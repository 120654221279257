import React, { lazy, Suspense, memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ConfigProvider, message } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes';
import { SetAuthUser } from 'store/slices/authSlice';
import { apis } from 'apis';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

export const MessageContext = React.createContext(null);

const Layouts = () => {
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const token = useSelector(state => state.auth.token);
	const blankLayout = useSelector(state => state.theme.blankLayout);

	const Layout = token && !blankLayout ? AppLayout : AuthLayout;

	const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme }

	const GetCurrentUser = async () => {
		try {
			const response = await apis.GetCurrentUser();
			if (response.status) {
				dispatch(SetAuthUser(response.payload.user));
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (token) {
			GetCurrentUser();
		}
	}, [token])

	return (
		<ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
			<Suspense fallback={<Loading cover="content" />}>
				<MessageContext.Provider value={messageApi}>
					{contextHolder}
					<Layout>
						<Routes />
					</Layout>
				</MessageContext.Provider>
			</Suspense>
		</ConfigProvider>
	)
}

export default memo(Layouts)