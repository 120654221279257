import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import question from "./slices/questionSlice"
import user from './slices/userSlice'
import report from "./slices/reportSlice"

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        question,
        user,
        report,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
