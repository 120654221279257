import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    investor: null,
    questions: [],
    assessments: []
}

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        SetInvestor: (state, action) => {
            state.investor = action.payload
        },
        SetQuestions: (state, action) => {
            state.questions = action.payload
        },
        SetAssessments: (state, action) => {
            state.assessments = action.payload
        }
    },
})

export const {
    SetInvestor,
    SetQuestions,
    SetAssessments
} = questionSlice.actions

export default questionSlice.reducer