import axios, { AxiosHeaders } from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";

const API = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
});

API.interceptors.request.use((config) => {
    try {
        let token = localStorage.getItem(AUTH_TOKEN);
        if (token) {
            const mHeaders = AxiosHeaders.from({
                Authorization: `Bearer ${token}`,
            });

            if (mHeaders) {
                config.headers = mHeaders;
            }
        }
    } catch (error) {
        console.log(error);
    }

    return config;
});

API.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        try {
            if (error.response.status === 401 && error.response.data.auth === true) {
                localStorage.removeItem(AUTH_TOKEN);
                window.location.href = "/";
            }
            return Promise.reject(error);
        } catch (e) {
            console.log(error);
        }
    }
);

// Auth
const AdminSignIn = (data) => API.post('/api/v1/auth/admin-signin', data);
const GetCurrentUser = () => API.get("/api/v1/auth/admin-current-user");

// Question
const CreateQuestion = (data) => API.post("/api/v1/question", data);
const GetQuestionListByInvestor = (investor) => API.get(`/api/v1/question/list?investor=${investor}`);
const DeleteQuestionByIdAndIndex = (id, index) => API.delete(`/api/v1/question/${id}?index=${index}`);
const UpdateQuestionByIdAndIndex = (id, index, data) => API.patch(`/api/v1/question/${id}?index=${index}`, data);
const CreateAssessmentCondition = (id, data) => API.patch(`/api/v1/question/${id}/condition`, data);
const RemoveConditionById = (id) => API.delete(`/api/v1/question/${id}/condition`);
const SetQuestionOrder = (id, data) => API.patch(`/api/v1/question/${id}/reorder`, data);
const GenerateQuestions = (data) => API.post('/api/v1/question/generate', data);

// Assessment
const CreateAssessment = (data) => API.post("/api/v1/assessment", data);
const GetAssessmentListByInvestor = (investor) => API.get(`/api/v1/assessment?investor=${investor}`);
const DeleteAssessmentByIndex = (investor, index) => API.delete(`/api/v1/assessment/${index}?investor=${investor}`);
const UpdateAssessmentByIndex = (investor, index, data) => API.patch(`/api/v1/assessment/${index}?investor=${investor}`, data);
const SetAssessmentOrder = (investor, data) => API.patch(`/api/v1/assessment/reorder?investor=${investor}`, data);

// User
const GetUserList = () => API.get("/api/v1/user/list");

// Report
const GetReportList = (search) => API.get(`/api/v1/report/list?search=${search}`);
const GetReportById = (id) => API.get(`/api/v1/report/${id}`);
const UpdateReportById = (id, data) => API.patch(`/api/v1/report/${id}`, data);
const RegenerateReport = (id) => API.get(`/api/v1/report/${id}/re-generate`);
const DeleteReportById = (id) => API.delete(`/api/v1/report/${id}`);

// Setting
const CreateIntroQuestion = (data) => API.post("/api/v1/setting/intro-question", data);
const GetIntroQuestionList = () => API.get(`/api/v1/setting/intro-question/list`);
const DeleteIntroQuestion = (index, type) => API.delete(`/api/v1/setting/intro-question/${index}?type=${type}`);
const SelectIntroQuestion = (data) => API.patch(`/api/v1/setting/intro-question/select`, data);
const UpdateIntroQuestion = (data) => API.patch(`/api/v1/setting/intro-question`, data);
const CreateInvestor = (data) => API.post("/api/v1/setting/investor", data);
const GetInvestorList = () => API.get(`/api/v1/setting/investor/list`);
const UpdateInvestor = (id, data) => API.patch(`/api/v1/setting/investor/${id}`, data);

// File
const GetUserFileList = (data) => API.post('/api/v1/file/list', data);
const RemoveFileById = (id) => API.delete(`/api/v1/file/${id}`);
const AdminFileUpload = (data) => API.post('/api/v1/file/admin-upload', data);

export const apis = {
    AdminSignIn,
    GetCurrentUser,

    CreateAssessment,
    GetAssessmentListByInvestor,
    DeleteAssessmentByIndex,
    UpdateAssessmentByIndex,
    SetAssessmentOrder,

    CreateQuestion,
    GetQuestionListByInvestor,
    DeleteQuestionByIdAndIndex,
    UpdateQuestionByIdAndIndex,
    CreateAssessmentCondition,
    RemoveConditionById,
    SetQuestionOrder,
    GenerateQuestions,

    GetUserList,

    GetReportList,
    GetReportById,
    UpdateReportById,
    RegenerateReport,
    DeleteReportById,

    CreateIntroQuestion,
    GetIntroQuestionList,
    DeleteIntroQuestion,
    SelectIntroQuestion,
    UpdateIntroQuestion,
    CreateInvestor,
    GetInvestorList,
    UpdateInvestor,

    GetUserFileList,
    RemoveFileById,
    AdminFileUpload
};
