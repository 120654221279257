import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
]

export const protectedRoutes = [
    // {
    //     key: 'dashboard',
    //     path: `${APP_PREFIX_PATH}/dashboards`,
    //     component: React.lazy(() => import('views/app-views/dashboards')),
    // },
    {
        key: 'user',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users')),
    },
    {
        key: 'question',
        path: `${APP_PREFIX_PATH}/questions`,
        component: React.lazy(() => import('views/app-views/questions')),
    },
    {
        key: 'assessment',
        path: `${APP_PREFIX_PATH}/assessments`,
        component: React.lazy(() => import('views/app-views/assessments')),
    },
    {
        key: 'report',
        path: `${APP_PREFIX_PATH}/reports`,
        component: React.lazy(() => import('views/app-views/reports')),
    },
    {
        key: 'setting',
        path: `${APP_PREFIX_PATH}/setting`,
        component: React.lazy(() => import('views/app-views/setting')),
    }
]