import { createSlice } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';

export const initialState = {
	token: localStorage.getItem(AUTH_TOKEN) || null,
	user: null,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		SignInSuccess: (state, action) => {
			localStorage.setItem(AUTH_TOKEN, action.payload)
			state.token = action.payload
		},
		SignOutSuccess: (state, action) => {
			state.token = null
			state.user = null
			localStorage.removeItem(AUTH_TOKEN)
		},
		SetAuthUser: (state, action) => {
			state.user = action.payload;
		}
	},
})

export const {
	SignInSuccess,
	SignOutSuccess,
	SetAuthUser
} = authSlice.actions

export default authSlice.reducer