import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    users: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        SetUsers: (state, action) => {
            state.users = action.payload
        }
    },
})

export const {
    SetUsers
} = userSlice.actions

export default userSlice.reducer