import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    reports: []
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        SetReports: (state, action) => {
            state.reports = action.payload
        },
    },
})

export const {
    SetReports
} = reportSlice.actions

export default reportSlice.reducer